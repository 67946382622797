import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from '../../../assets/diplomatas.png';
import Bars from '../../../assets/bars.png';
import { Link } from "react-scroll";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="header">
      <img src={Logo} alt="Logo" className='logo' />

      {!menuOpened && mobile ? (
        <div style={{backgroundColor:'var(--appColor)', padding:"0.5rem", borderRadius:"5px"}}>
          <img src={Bars} alt="Menu" style={{with: '1.5rem', height:'1.5rem'}} onClick={() => setMenuOpened(true) } />
        </div>
      ) : (
        <ul className='header-menu'>
          <li>
            <Link 
              onClick={() => setMenuOpened(false)}
              to='home'
              spy={true}
              smooth={true}
              duration={500}
            >
              Home
            </Link>
          </li>
          <li><Link 
              onClick={() => setMenuOpened(false)}
              to='programs'
              spy={true}
              smooth={true}
              duration={500}
            >
              Programação
            </Link></li>
          <li><Link 
              onClick={() => setMenuOpened(false)}
              to='reasons'
              spy={true}
              smooth={true}
              duration={500}
            >
              Sobre nós
            </Link></li>
          <li ><Link 
              onClick={() => setMenuOpened(false)}
              to='plans'
              spy={true}
              smooth={true}
              duration={500}
            >
              Jogos
            </Link></li>
          <li>
            <Link 
              onClick={() => setMenuOpened(false)}
              to='Testimonials'
              spy={true}
              smooth={true}
              duration={500}
            >
              Comissão Técnica
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Header;
