import React from 'react'
import './Reasons.css'
import image1 from "../../assets/image1.jpg"
import image2 from "../../assets/image2.jpg"
import image3 from "../../assets/image3.jpg"
import image4 from "../../assets/image4.jpg"
import nb from "../../assets/Logo.png"
import adidas from "../../assets/gentedagente.webp"
import nike from "../../assets/fpf.png"
import tick from "../../assets/tick.png"

const Reasons = () => {
  return (
    <div className="Reasons" id='reasons'>
        <div className="left-r">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
            <img src={image3} alt="" />
            <img src={image4} alt="" />
        </div>
        <div className="right-r">
            <span>algumas razões</span>

            <div>
                <span className='stroke-text'>Porquê</span>
                <span> nos escolher ? </span>
            </div>

            <div className='details-r'>
            <div>
                <img src={tick} alt="">
                </img>
                <span>treinadores especializados</span>
            </div>
            <div>
                <img src={tick} alt="" />
                <span>treine mais inteligente e mais rápido do que antes</span>
            </div>
            <div>
                <img src={tick} alt="" />
                <span>avaliação gratuito para novo membro</span>
            </div>
            <div>
                <img src={tick} alt="" />
                <span>parceiros confiáveis</span>
            </div>
        </div>
        <span style={{
            color: "var(--gray)",
            fontWeight: "normal",
        }}>Nossos Parceiros</span>

        <div className="partners">
            <img src={nb} alt="" />
            <img src={adidas} alt="" />
            <img src={nike} alt="" />
        </div>
        </div>
    </div>
  )
}

export default Reasons