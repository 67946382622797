import React from 'react'
import './Hero.css'
import Header from './Header/Header'
import hero_image from "../../assets/lukinhas.png"
import hero_image_back from "../../assets/hero_image_back.png"
/*import heart from "../../assets/heart.png"*/
/*import Calories from "../../assets/calories.png"*/
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'


const Hero = () => {

  const transition = { type: 'spring', duration: 3 }
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero " id='home'>

      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile? "165px": '238px' }}
            whileInView={{ left: '8px' }}
            transition={{ ...transition, type: 'tween' }}
          ></motion.div>
          <span>O Melhor clube da cidade</span>
        </div>

        {/* hero heading */}
        <div className="hero-text">
          <div>
            <span className='stroke-text'>Sonhe, </span>
            <span>Treine,</span>
          </div>
          <div>
            <span>Inspire e Realize</span>
          </div>
          <div>
            <span>
              "No Diplomatas FC, você vai compreender a importância da evolução tanto individual quanto coletiva,
              além de ter a confiança de que terá acesso a oportunidades concretas para demonstrar seu talento.
              Aproveite a expertise do Diplomatas que já proporcionou inumeras oportunidades por meio de treinamentos
              de excelência e avaliações de feedback."
            </span>
          </div>
        </div>
        {/*Figures */}
        <div className="figures">
          <div>
          <span class="game-icons--team-upgrade">
            <NumberCounter end={140} start={100} delay='4' prefix="+"/>
          </span>
            <span>Professores experientes</span>
          </div>
          <div>
            <span class="game-icons--soccer-kick">
            <NumberCounter end={140} start={100} delay='4' prefix="+"/>
            </span>
            <span>+68 Alunos cadastrados</span>
          </div>
          <div>
            <span class="game-icons--soccer-field">

            <NumberCounter end={140} start={100} delay='4' prefix="+"/>
            </span>
            <span>participações em competições importanttes</span>
          </div>
        </div>
        {/* buttons*/}
        <div className="heros-buttons">
          <hr />
        </div>
      </div>
      <div className="right-h">
        {/* <button className='btn'></button> */}

       {/*  <motion.div
          initial={{ left: '180px' }}
        ></motion.div>

        <motion.div className="heart-rate"
          whileInView={{ right: '8px' }}
          transition={{ ...transition, type: 'tween' }}

        >
          <img src={heart} alt="" />
          <span>Heart rate</span>
          <span>116 bpm</span>
        </motion.div> */}

        {/*images */}
        <img src={hero_image} alt="" className='hero-image' />
        <motion.img 
        
        
                initial={{opacity: 0, x:100}}
                animate={{opacity: 1, x:0}}
                exit={{opacity: 0, x: -100}}
                transition={transition}
      
        src={hero_image_back} alt="" className='hero_image_back' />

        {/*Calories*/}
        {/*  <div className="calories">
          <img src={Calories} alt="" />
          <div>
          <span>Calorias queimadas</span>
          <span>220 Kcal</span>
          </div>
        </div> */}

      </div>
    </div>
  )
}

export default Hero