import React from 'react'
import './Plans.css'
import { plansData } from '../../data/plansData'
import whitheTick from '../../assets/whiteTick.png'

const Plans = () => {
    return (
        <div className="plans-container">
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className="Programs-header" style={{ gap: '1rem', padding: '0 8rem' }}>
                <span className='stroke-text'>Pronto para Começar</span>
                <span> Sua Jornada</span>
                <span className='stroke-text'> agora, conosco</span>
            </div>

            {/*cartão de planos */}
            <div className="plans">
                {plansData.map((plan, i) => (
                    <div className="plan" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>{plan.price}</span>

                        <div className="features">
                            {plan.features.map((feaure, i) => (
                                <div className="feature">
                                    <img src={whitheTick} alt="" />
                                    <span key={i}>{feaure}</span>
                                </div>
                            ))}
                        </div>
                        <div>
                            <span></span>
                        </div>
                        <a href="https://api.whatsapp.com/send?phone=554196180654" target='blank'>
                            <button className="btn">Registre se</button>
                        </a>

                    </div>

                ))}
            </div>

        </div>
    )
}

export default Plans