import React, { useState } from 'react';
import './Testimonials.css';
import { testimonialsData } from '../../data/testimonialsData';
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png';
import { motion } from 'framer-motion';

const Testimonials = () => {
    const transition = { type: 'spring', duration: 3 };
    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;

    const handleLeftClick = () => {
        setSelected((prev) => (prev === 0 ? tLength - 1 : prev - 1));
    };

    const handleRightClick = () => {
        setSelected((prev) => (prev === tLength - 1 ? 0 : prev + 1));
    };

    return (
        <div className="Testimonials">
            <div className="left-t">
                <span>Comissão Técnica</span>
                <span className='stroke-text'>o que eles </span>
                <span>falam de nós</span>

                {testimonialsData[selected] && (
                    <>
                        <motion.span
                            key={selected}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={transition}
                        >
                            {testimonialsData[selected].review}
                        </motion.span>
                        <span>
                            <span style={{ color: 'var(--orange)' }}>
                                {testimonialsData[selected].name}
                            </span>{" "}
                            - {testimonialsData[selected].status}
                        </span>
                    </>
                )}
            </div>

            <div className="right-t">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    transition={{ ...transition, duration: 2 }}
                    whileInView={{ opacity: 1, x: 0 }}
                />
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    transition={{ ...transition, duration: 2 }}
                    whileInView={{ opacity: 1, x: 0 }}
                />

                {testimonialsData[selected] && (
                    <motion.img
                        key={selected}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={transition}
                        src={testimonialsData[selected].image} 
                        alt={testimonialsData[selected].name} 
                    />
                )}

                <div className="arrows">
                    <img
                        onClick={handleLeftClick}
                        src={leftArrow} 
                        alt="Left Arrow" 
                    />
                    <img
                        onClick={handleRightClick}
                        src={rightArrow} 
                        alt="Right Arrow" 
                    />
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
