import image1 from "../assets/willian .png";
import image2 from "../assets/nascimento.png";
import image3 from "../assets/nainha.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Fiz a escolha certa ao escolher o Diplomatas e ao escolher a escolha certa já alcancei o time ideal!!",
    name: 'Professor Wilhan',
    status : 'Vice Presidente'
  },
  {
    image: image2,
    review: 'Escolhemos o Clube certo para a realização de parceria....e que em breve tenhamos um jogador nosso e trilhando o caminho do Profissional. ',
    name: 'MARCOS NASCIMENTO',
    status: 'TECNICO'
  },
  {
    image : image3,
    review:' Futebol é facil de jogar é só fazer o basico, mas poucos sabem fazer o basico bem feito!',
    name: 'FABIO NAINHA',
    status: "PRESIDENTE"
  }
];
