import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
import Qrcode from '../../assets/qrcode gdg.png'


const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_u67tcdk', 'template_ynsfkjl', form.current, {
            publicKey: 'uQ6gO7yaKiqfWeQTb',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };
  return (
    <div className="Join">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>Pronto para </span>
                <span>Subir o nível </span>
            </div>
            <div>
                <span> do seu </span>
                <span className='stroke-text'> futebol? </span>
            </div>
        </div>
        <div className="right-j">
          <div className="qrcode">
            <img src={Qrcode} alt="" />
            <h1>Escaneie o qrcode e entre em contato conosco!</h1>
          </div>
          <div className="cad">
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd5pJsqYQ_Y8j5fsjsFMj6cgnEW9A6Su5zzLLEGRjEv0I0xAQ/viewform?embedded=true" width="640" height="2299" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                </div>
            {/* <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" id='email' name="user_mail" placeholder='seuemail@gmail.com' />
                <button className='btn btn-j'>Join now</button>
            </form> */}

        </div>

    </div>
  )
}

export default Join