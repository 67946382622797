import React from 'react'
import './Programs.css'
import { programsData}  from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'

const Programs = () => {
  return (
    <div className="Programs" id='programs'>
        {/*header */}
        <div className="Programs-header">
            <span className='stroke-text'>Adulto</span>
            <span>Juvenil</span>
            <span className='stroke-text'>Escolinha</span>
        </div>

        <div className="program-categories">
            {programsData.map((program)=>(
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                    <div className="join-now">
                        <img src={RightArrow} alt="" />
                    <a href="https://api.whatsapp.com/send?phone=554196180654" target='blank'>
                            <button className="btn">Registre se</button>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Programs